<template>
  <div class="initiate-view">
    <tojoy-title-content title="我审批的">
      <template #footer>
        <div class="tojoy-title-content__footer">
          <tojoy-tabs v-model="tabName" :tabs="tabs" @tab-click="handleTabClick" />
        </div>
      </template>
    </tojoy-title-content>
    <tojoy-pagination-list
      :data="listData"
      :total="total"
      ref="tojoy-pagination-list-ref"
      @page-change="handleChangePage"
      schema="block"
      :loading="loading"
    >
      <template #item="{data}">
        <card :data="data" type="approval" :moduleName="moduleName">
          <template #num>
            <span class="num">{{ data.finalScore | formateFinalScore(data.processState) }}</span>
          </template>
          <template #status>
            <span class="status" :class="`status-${data.processState}`">{{
              data.processName | formateProcessState(data.approverName, data.processState)
            }}</span>
          </template>
        </card>
      </template>
    </tojoy-pagination-list>
  </div>
</template>

<script>
import TojoyTitleContent from '@/components/common/initiate-team/title-content'
import TojoyPaginationList from '@/components/business/pagination-list'
import { approvalTabNames } from '@/components/initiate/state.js'
import tabsRouteMixins from '@/mixins/tabsRouteMixins'
import listMixin from '@/mixins/initiate/listMixin.js'
import card from '@/components/initiate/card'
import { getMeApprove, getListCount } from '@/api/api_innovation'
import TojoyTabs from '@/components/business/tabs'
export default {
  name: 'approval',
  mixins: [listMixin, tabsRouteMixins],
  components: {
    card,
    TojoyTitleContent,
    TojoyPaginationList,
    TojoyTabs
  },
  props: {
    moduleName: {
      type: String,
      default: 'approval'
    }
  },
  data() {
    return {
      tabs: approvalTabNames
    }
  },
  created() {
    this.getCount()
    let obj = {
      approval: 0,
      complete: 1
    }
    this.tabIndex = obj[this.moduleName]
    this.loading = true
    this.getList()
  },
  methods: {
    getCount() {
      getListCount({ innovateState: 1 })
        .then(res => {
          //待提交 toBeSubmitted,审批中 underReview
          let { approvalPending: approval } = res.data
          this.$set(this.tabs[0], 'badge', approval)
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 列表
     */
    getList(state = 0) {
      let params = {
        page: this.page,
        size: 10,
        innovateState: state || this.tabIndex,
        companyId: 0
      }
      getMeApprove(params)
        .then(res => {
          let { list, total } = res.data
          this.listData = list
          this.total = total
          //promoterName 发起人
          this.listData.forEach(el => {
            el.companyName = el.promoterName || ''
          })
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    handleChangePage(val) {
      this.page = val
      this.loading = true
      this.getList()
    },
    handleTabClick({ index }) {
      this.tabIndex = index
      this.listData = []
      this.total = 0
      this.page = 1
      this.size = 10
      this.loading = true
      this.$refs['tojoy-pagination-list-ref'].resetScrollTop()
      this.getList(index)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/approval.scss';
</style>
